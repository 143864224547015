import React from "react";
import JACard from "../../shared/JACard";
import JACollabsBody from "./JACollabsBody";
import { useTranslation } from "react-i18next";
import Cat from "../../assets/images/cat-2.png";
import Dog from "../../assets/images/dog-3.png";
export default function JACollabs() {
  const { t } = useTranslation();
  return (
    <JACard
      header={t("NOS COLLABORATEURS")}
      LeftImage={Cat}
      RightImage={Dog}
      body={<JACollabsBody />}
    />
  );
}
