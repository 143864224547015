import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  fallbackLng: "fr",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        "Soutenir la cause animale en Tunisie":
          "Support the animal cause in Tunisia.",
        "À PROPOS DE NOUS": "ABOUT US",
        "PRÉSENTATION DE L'ASSOCIATION": "PRESENTATION OF THE ASSOCIATION",
        "NOS MISSIONS ET OBJECTIFS": "OUR MISSIONS AND OBJECTIVES",
        "Je suis la présidente et fondatrice de l’Association « Les Jardins des Anges » de Kalaa Kebira à Sousse en Tunisie.":
          'I\'m the president and founder of the Association "Les Jardins des Anges" in Kalaa Kebira, Sousse, Tunisia.',
        "Bonjour, mon nom est Tiziana Gamannossi.":
          "Hello, my name is Tiziana Gamannossi.",
        "Passionnée par la cause animale depuis toujours, je ne pouvais rester indifférente au martyre des chiens errants en Tunisie. Souvent maltraités, sous-alimentés, pourchassés et tués par dizaines chaque jour, nos chiens vivent un véritable calvaire.":
          "Having always been passionate about the animal cause, I couldn't remain indifferent to the plight of stray dogs in Tunisia. Often mistreated, underfed, hunted down and killed by dozen every day, our dogs live a real ordeal.",
        "Voilà pourquoi j’ai décidé il y a 8 ans de créer un premier refuge à Djerba, Gouvernorat de Medenine.":
          "That's why I decided 8 years ago to set up our first shelter in Djerba, in the Medenine Governorate.",
        "Ensuite nous avons déménagé plusieurs fois et face à l’augmentation des chiens et chats à recueillir et sauver du martyre qu’ils subissent, en août 2021 nos deux refuges actuels ont vu le jour.":
          "We then moved several times and, faced with the increasing number of dogs and cats to take in and save from their sufferings, our two current shelters came into being in August 2021.",
        "Aujourd’hui, avec mon équipe, nous avons la responsabilité de gérer ces deux refuges où nous hébergeons environs 380 chiens et plus de 130 chats. Mon rôle est de superviser et de participer à toutes les activités du refuge, de veiller au bien-être des animaux, de trouver des solutions pour répondre à leurs besoins et garantir le bon fonctionnement de l’Association dans son ensemble. Mon objectif est de travailler sans relâche avec mon équipe pour offrir le meilleur soutien possible aux animaux et accomplir notre mission avec succès.":
          "Today, my team and I are responsible for managing these two shelters, which house around 380 dogs and over 130 cats. My role is to supervise and participate in all shelter activities, to ensure the animals' well-being, to find solutions to meet their needs and to guarantee the smooth running of the Association as a whole. My goal is to work tirelessly with my team to offer the best possible support to the animals and successfully accomplish our mission.",
        "Au cours de ces 8 années, j’ai pu acquérir une expérience certaine de la situation des chiens errants en Tunisie et des problèmes liés à l’environnement encore non résolus à ce jour":
          "Over the past 8 years, I have gained a great deal of experience of the situation of stray dogs in Tunisia, and of the environmental problems that remain unresolved to this day",
        notamment: "including",
        "tenir sous contrôle deux graves maladies largement répandues en Tunisie : la rage et la leishmaniasi chez les chiens et la rage en ce qui concerne les chats":
          "resolve the problems of mass tourism by removing food waste from hotels and sorting and recycling it completely) and thus do everything possible to save as many dogs and cats as possible",
        et: "and",
        "La tâche est titanesque, chaque jour est un nouveau combat à mener mais ma détermination est sans faille.":
          "It's a titanic task, and every day is a new battle to wage, but my determination is unfailing.",
        "et ainsi mettre tout en œuvre pour sauver un maximum de chiens et de chats et leur apporter tous les soins nécessaires ainsi que la vaccination et la stérilisation.":
          "and provide them with all the care they need, as well as vaccinations and sterilization.",
        "FAIRE UN DON": "MAKE A DONATION",
        "Lien ici": "Click here",
        "NOS COLLABORATEURS": "OUR STAFF",
        "Pour nous soutenir dans notre tâche, nous avons sollicité le soutien de plusieurs hôtels de la région. Ces hôtels collaborent avec nous en nous confiant les restes de leurs restaurants au bénéfice des animaux de nos deux refuges de Kalaa Kebira à Sousse.":
          "To help us in our task, we have enlisted the support of several hotels in the region. These hotels collaborate with us by entrusting us with the leftovers from their restaurants for the benefit of the animals at our two shelters in Kalaa Kebira and Sousse.",
        "Chaque soir, nous faisons le tour des hôtels de Monastir, Sousse et Hammam-Sousse avec nos deux camionnettes pour récupérer les repas que nous trions soigneusement et distribuons ensuite à nos protégés. Nos chiens et chats ne mangeant pas les légumes crus, nous les offrons aux voisins propriétaires d’herbivores.":
          "Every evening, we make the rounds of the hotels in Monastir, Sousse and Hammam-Sousse with our two vans to collect the meals, which we then carefully sort and distribute to our protégés. As our dogs and cats don't eat raw vegetables, we offer them to our neighbors who own herbivores.",
        "Nous remercions chaleureusement ces hôtels car grâce à eux, nous pouvons aider beaucoup plus d’animaux.":
          "We would like to extend our warmest thanks to these hotels, as they enable us to help many more animals.",
        "Nous espérons que cette démarche de collaboration permettra le début d’un grand changement à Sousse et, si possible, dans toute la Tunisie.":
          "We hope that this collaborative approach will be the start of a great change in Sousse and, if possible, throughout Tunisia.",
        "Si vous cherchez où passer vos vacances, nous vous recommandons fortement ces hôtels « bienveillants » sans hésiter.":
          'If you\'re looking for a place to spend your vacation, we highly recommend these "caring" hotels without hesitation.',
        CONTACTS: "CONTACT US",
        "RÉSEAUX SOCIAUX": "SOCIAL NETWORKS",
        "NOTRE VÉTÉRINAIRE": "OUR VETERINARIAN",
        "Tous droits réservés": "All rights reserved",
        "Matricule Fiscal": "Tax number",
        "Virement depuis la Tunisie": "Transfer from Tunisia",
        "Virement depuis l'étranger": "Transfer from abroad",
        "Demander le RIB en France": "Ask for the RIB in France",
        "Frais d'un virement d'une banque étrangère vers une banque tunisienne coûte 30€": "The cost of a transfer from a foreign bank to a Tunisian bank is around 30€"
      },
    },
    de: {
      translation: {
        "Soutenir la cause animale en Tunisie":
          "Unterstützen Sie den Tierschutz in Tunesien",
        "À PROPOS DE NOUS": "Über uns",
        "PRÉSENTATION DE L'ASSOCIATION": "Vorstellung des Vereins",
        "NOS MISSIONS ET OBJECTIFS": "Unsere Aufgaben und Ziele",
        "Je suis la présidente et fondatrice de l’Association « Les Jardins des Anges » de Kalaa Kebira à Sousse en Tunisie.":
          'Ich bin die Vorsitzende und Gründerin des Vereins "Les Jardins des Anges" in Kalaa Kebira in Sousse in Tunesien',
        "Bonjour, mon nom est Tiziana Gamannossi.":
          "Hallo, mein Name ist Tiziana Gamannossi",
        "Passionnée par la cause animale depuis toujours, je ne pouvais rester indifférente au martyre des chiens errants en Tunisie. Souvent maltraités, sous-alimentés, pourchassés et tués par dizaines chaque jour, nos chiens vivent un véritable calvaire.":
          "Da ich mich schon immer leidenschaftlich für die Sache der Tiere eingesetzt habe, konnte mir das Leiden der Straßenhunde in Tunesien nicht gleichgültig bleiben. Unsere Hunde werden oft misshandelt, unterernährt, gejagt und jeden Tag zu Dutzenden getötet, sodass sie ein wahres Leiden durchleben.",
        "Voilà pourquoi j’ai décidé il y a 8 ans de créer un premier refuge à Djerba, Gouvernorat de Medenine.":
          "Aus diesem Grund habe ich vor acht Jahren beschlossen, ein erstes Tierheim in Djerba, Gouvernorat Medenine, zu gründen.",
        "Ensuite nous avons déménagé plusieurs fois et face à l’augmentation des chiens et chats à recueillir et sauver du martyre qu’ils subissent, en août 2021 nos deux refuges actuels ont vu le jour.":
          "Danach zogen wir mehrmals um, und als immer mehr Hunde und Katzen aufgenommen und vor dem Leiden bewahrt werden mussten, entstanden im August 2021 unsere beiden aktuellen Tierheime.",
        "Aujourd’hui, avec mon équipe, nous avons la responsabilité de gérer ces deux refuges où nous hébergeons environs 380 chiens et plus de 130 chats. Mon rôle est de superviser et de participer à toutes les activités du refuge, de veiller au bien-être des animaux, de trouver des solutions pour répondre à leurs besoins et garantir le bon fonctionnement de l’Association dans son ensemble. Mon objectif est de travailler sans relâche avec mon équipe pour offrir le meilleur soutien possible aux animaux et accomplir notre mission avec succès.":
          "Heute sind mein Team und ich für die Leitung dieser beiden Tierheime verantwortlich, in denen wir etwa 380 Hunde und über 130 Katzen unterbringen. Meine Aufgabe ist es, alle Aktivitäten im Tierheim zu überwachen und daran teilzunehmen, für das Wohlergehen der Tiere zu sorgen, Lösungen für ihre Bedürfnisse zu finden und das reibungslose Funktionieren des Vereins als Ganzes zu gewährleisten. Mein Ziel ist es, mit meinem Team unermüdlich daran zu arbeiten, den Tieren die bestmögliche Unterstützung zu bieten und unsere Mission erfolgreich zu erfüllen.",
        "Au cours de ces 8 années, j’ai pu acquérir une expérience certaine de la situation des chiens errants en Tunisie et des problèmes liés à l’environnement encore non résolus à ce jour":
          "In diesen acht Jahren habe ich einige Erfahrungen mit der Situation streunender Hunde in Tunesien und den bis heute ungelösten Umweltproblemen sammeln können",
        notamment: "u.a.",
        "tenir sous contrôle deux graves maladies largement répandues en Tunisie : la rage et la leishmaniasi chez les chiens et la rage en ce qui concerne les chats":
          "zwei schwere, in Tunesien weit verbreitete Krankheiten unter Kontrolle zu halten: Tollwut und Leishmaniasi bei Hunden und Tollwut in Bezug auf Katzen",
        et: "und",
        "La tâche est titanesque, chaque jour est un nouveau combat à mener mais ma détermination est sans faille.":
          "Die Aufgabe ist titanisch, jeder Tag ist ein neuer Kampf, den es zu bestehen gilt, aber meine Entschlossenheit ist unerschütterlich.",
        "et ainsi mettre tout en œuvre pour sauver un maximum de chiens et de chats et leur apporter tous les soins nécessaires ainsi que la vaccination et la stérilisation.":
          "so alles daran setzen, möglichst viele Hunde und Katzen zu retten und ihnen alle notwendige Pflege sowie Impfungen und Sterilisationen zukommen zu lassen.",
        "FAIRE UN DON": "Eine Spende machen",
        "Lien ici": "Hier klicken",
        "NOS COLLABORATEURS": "Unsere Mitarbeiterinnen und Mitarbeiter",
        "Pour nous soutenir dans notre tâche, nous avons sollicité le soutien de plusieurs hôtels de la région. Ces hôtels collaborent avec nous en nous confiant les restes de leurs restaurants au bénéfice des animaux de nos deux refuges de Kalaa Kebira à Sousse.":
          "Um uns bei unserer Aufgabe zu unterstützen, haben wir mehrere Hotels in der Region um Unterstützung gebeten. Diese Hotels arbeiten mit uns zusammen, indem sie uns die Reste ihrer Restaurants zugunsten der Tiere in unseren beiden Tierheimen in Kalaa Kebira in Sousse anvertrauen",
        "Chaque soir, nous faisons le tour des hôtels de Monastir, Sousse et Hammam-Sousse avec nos deux camionnettes pour récupérer les repas que nous trions soigneusement et distribuons ensuite à nos protégés. Nos chiens et chats ne mangeant pas les légumes crus, nous les offrons aux voisins propriétaires d’herbivores.":
          "Jeden Abend fahren wir mit unseren beiden Kleintransportern durch die Hotels in Monastir, Sousse und Hammam-Sousse, um die Mahlzeiten einzusammeln, die wir sorgfältig sortieren und dann an unsere Schützlinge verteilen. Da unsere Hunde und Katzen das rohe Gemüse nicht fressen, bieten wir es den Nachbarn an, die Pflanzenfresser besitzen.",
        "Nous remercions chaleureusement ces hôtels car grâce à eux, nous pouvons aider beaucoup plus d’animaux.":
          "Wir bedanken uns herzlich bei diesen Hotels, denn dank ihrer Hilfe können wir viel mehr Tieren helfen.",
        "Nous espérons que cette démarche de collaboration permettra le début d’un grand changement à Sousse et, si possible, dans toute la Tunisie.":
          "Wir hoffen, dass dieser Schritt der Zusammenarbeit den Beginn einer großen Veränderung in Sousse und, wenn möglich, in ganz Tunesien ermöglicht.",
        "Si vous cherchez où passer vos vacances, nous vous recommandons fortement ces hôtels « bienveillants » sans hésiter.":
          'Wenn Sie auf der Suche nach einem Ort sind, an dem Sie Ihren Urlaub verbringen können, können wir Ihnen diese "wohlwollenden" Hotels ohne zu zögern wärmstens empfehlen',
        CONTACTS: "Kontakte",
        "RÉSEAUX SOCIAUX": "Soziale Netzwerke",
        "NOTRE VÉTÉRINAIRE": "Unser Tierarzt",
        "Tous droits réservés": "Alle Rechte vorbehalten",
        "Matricule Fiscal": "Steuernummer",
        "Virement depuis la Tunisie": "Überweisung aus Tunesien",
        "Virement depuis l'étranger": "Überweisung aus dem Ausland",
        "Demander le RIB en France": "RIB in Frankreich anfordern",
        "Frais d'un virement d'une banque étrangère vers une banque tunisienne coûte 30€": "Die Kosten für eine Überweisung von einer ausländischen Bank auf eine tunesische Bank belaufen sich auf ca. 30€"
      },
    },
  },
});
export default i18n;
