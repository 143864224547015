import React from "react";
import { Image, Nav, Row } from "react-bootstrap";
import JALogo from "../assets/images/logo.png";
import "../assets/styles/janavbar.scss";
import FacebookIcon from "../assets/images/icons/facebook.svg";
import InstagramIcon from "../assets/images/icons/instagram.svg";
import TiktokIcon from "../assets/images/icons/tiktok.svg";
import MailIcon from "../assets/images/icons/mail.svg";
import PhoneIcon from "../assets/images/icons/phone.svg";
import JASelectLang from "../components/JASelectLang";

export default function JANavbar() {
  return (
    <Row className="janavbar-row">
      <Nav justify variant="tabs" defaultActiveKey="/home" className="janavbar">
        <Nav.Item>
          <img src={JALogo} alt="Logo" width={400} />
        </Nav.Item>
        <div className="janavbar-info">
          {" "}
          <Nav.Item className="align-self-center">
            <a href="mailto:jardindesanges5@gmail.com">
              {" "}
              <Image className="mx-2" src={MailIcon} />
              <span>jardindesanges5@gmail.com</span>
            </a>
          </Nav.Item>{" "}
          <Nav.Item className="align-self-center d-flex justify-content-end">
            <a href="tel:+21652589238" className="janavbar-phone">
              {" "}
              <Image className="mx-2" src={PhoneIcon} />
              <span>+216 52 589 238</span>
            </a>
          </Nav.Item>
          <Nav.Item className="janavbar-flags-socials">
            <JASelectLang />

            <div className="jasocials">
              <a
                href="https://www.facebook.com/groups/2880981048887892/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <Image src={FacebookIcon} />
              </a>
              <a
                href="https://www.instagram.com/les_jardins_des_anges_tunisie"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <Image src={InstagramIcon} />
              </a>
              <a
                href="https://www.tiktok.com/@le_jardin_des_anges"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={TiktokIcon} />
              </a>
            </div>
          </Nav.Item>
        </div>
      </Nav>
    </Row>
  );
}
