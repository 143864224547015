import React from "react";
import JANavbar from "../../Layout/JANavbar";
import JACarousel from "../../Layout/JACarousel";
import AboutView from "../about/AboutView";
import JADonate from "../donate/JADonate";
import JACollabs from "../collabs/JACollabs";
import JAFooter from "../../Layout/JAFooter";
import JAVideos from "../../Layout/JAVideos";
import PawsImage from "../../assets/images/paws.png";
import Cat2 from "../../assets/images/cat-2.png";
import Dog3 from "../../assets/images/dog-3.png";
import Cat from "../../assets/images/cat.png";
import Dog from "../../assets/images/dog.png";

import { Image, Row } from "react-bootstrap";
export default function HomeView() {
  return (
    <div className="d-flex flex-column gap-5">
      <JANavbar />
      <Row>
        <JACarousel />
        <AboutView />
        <JADonate />
        <JACollabs />
        <div className="d-flex justify-content-center mb-5 mt-5">
          <Image src={Cat2} width={90} />
          <Image src={Dog3} width={90} />
        </div>
        <JAVideos />
        <JAFooter />
      </Row>
    </div>
  );
}
