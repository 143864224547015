import React from "react";
import { useTranslation } from "react-i18next";

export default function JACopyright() {
  const { t } = useTranslation();
  return (
    <div className="jacopyright">
      <span>
        <span id="copyright">©</span> <b>LES JARDINS DES ANGES</b>.{" "}
        {t("Tous droits réservés")}.
      </span>
      <span>
        <b>{t("Matricule Fiscal")}</b> 1769181/X
      </span>
    </div>
  );
}
