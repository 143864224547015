import React from "react";
import JACard from "../../shared/JACard";
import JAAboutBody from "./JAAboutBody";
import { useTranslation } from "react-i18next";
import Cat from "../../assets/images/cat.png";
import Dog from "../../assets/images/dog.png";
export default function AboutView() {
  const { t } = useTranslation();
  return (
    <JACard
      header={t("À PROPOS DE NOUS")}
      body={<JAAboutBody />}
      LeftImage={Cat}
      RightImage={Dog}
    />
  );
}
