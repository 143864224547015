import React from "react";
import FranceFlag from "../assets/images/flags/france.png";
import UKFlag from "../assets/images/flags/uk.png";
import GermanyFlag from "../assets/images/flags/germany.png";

import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function JASelectLang() {
  const { t, i18n } = useTranslation();
  return (
    <div className="jaflags d-flex gap-2 align-self-center">
      <Image
        src={FranceFlag}
        role="button"
        width={30}
        height={30}
        onClick={() => i18n.changeLanguage("fr")}
        className="jaflag"
      />
      <Image
        src={UKFlag}
        role="button"
        width={30}
        height={30}
        onClick={() => i18n.changeLanguage("en")}
        className="jaflag"
      />
      <Image
        src={GermanyFlag}
        role="button"
        width={30}
        height={30}
        className="jaflag"
        onClick={() => i18n.changeLanguage("de")}
      />
    </div>
  );
}

export default JASelectLang;
