import React from "react";
import MessengerIcon from "../assets/images/icons/messenger-sm.svg";
import GeoIcon from "../assets/images/icons/geo-sm.svg";
import PhoneIcon from "../assets/images/icons/phone-sm.svg";
import MailIcon from "../assets/images/icons/mail-sm.svg";
import FacebookIcon from "../assets/images/icons/facebook-sm.svg";
import InstagramIcon from "../assets/images/icons/instagram-sm.svg";
import TiktokIcon from "../assets/images/icons/tiktok-sm.svg";

import { Image } from "react-bootstrap";
import JACopyright from "./JACopyright";
import { useTranslation } from "react-i18next";
function JAFooter() {
  const { t } = useTranslation();
  return (
    <div className="mt-5">
      <footer className="jafooter d-flex gap-5 justify-content-center pt-5 pb-5">
        <div>
          <h3>{t("CONTACTS")}</h3>
          <div className="d-flex flex-column gap-2">
            <span>
              {" "}
              <Image className="mx-2" src={MailIcon} />
              jardindesanges5@gmail.com
            </span>
            <span>
              {" "}
              <Image className="mx-2" src={PhoneIcon} />
              +216 52 589 238
            </span>
            <span>
              {" "}
              <Image className="mx-2" src={GeoIcon} />
              4060 Kalaa Kebira - Sousse Tunisie
            </span>
            <span>
              <Image className="mx-2" src={MessengerIcon} />
              Tiziana Gamannossi
            </span>
          </div>
        </div>
        <div>
          <h3>{t("RÉSEAUX SOCIAUX")}</h3>
          <div className="d-flex flex-column gap-2">
            {" "}
            <a href="https://www.facebook.com/groups/2880981048887892/">
              <Image className="mx-2" src={FacebookIcon} />
              Le jardin des anges
            </a>
            <a href="https://www.instagram.com/les_jardins_des_anges_tunisie">
              <Image className="mx-2" src={InstagramIcon} />
              les_jardins_des_anges_tunisie
            </a>
            <a href="https://www.tiktok.com/@le_jardin_des_anges">
              <Image className="mx-2" src={TiktokIcon} />
              le_jardin_des_anges
            </a>
          </div>
        </div>
        <div>
          <h3>{t("NOTRE VÉTÉRINAIRE")}</h3>
          <div className="d-flex flex-column gap-2">
            <span>
              <Image className="mx-2" src={GeoIcon} />
              La clinique des Orchidées Sahloul 3 Sousse : Dr Chahrazed Acheche{" "}
              <br />
              Kenani Lotissement 236 rue mongi grira sahloul 3 Sousse, Sousse
              4054
            </span>
            <span>
              {" "}
              <Image className="mx-2" src={PhoneIcon} />
              +216 55 026 000
            </span>
          </div>
        </div>
      </footer>
      <JACopyright />
    </div>
  );
}

export default JAFooter;
