import React from "react";
import { Container, Row } from "react-bootstrap";
import JASmallCard from "../../shared/JASmallCard";
import Image1 from "../../assets/images/collabs/image1.png";
import Image2 from "../../assets/images/collabs/image2.png";
import Image3 from "../../assets/images/collabs/image3.png";
import Image4 from "../../assets/images/collabs/image4.png";
import Image5 from "../../assets/images/collabs/image5.png";
import Image6 from "../../assets/images/collabs/image6.png";
import { useTranslation } from "react-i18next";

export default function JACollabsBody() {
  const { t } = useTranslation();
  return (
    <Container>
      <p className="jacard-p">
        {t(
          "Pour nous soutenir dans notre tâche, nous avons sollicité le soutien de plusieurs hôtels de la région. Ces hôtels collaborent avec nous en nous confiant les restes de leurs restaurants au bénéfice des animaux de nos deux refuges de Kalaa Kebira à Sousse."
        )}
      </p>
      <p className="jacard-p">
        {t(
          "Chaque soir, nous faisons le tour des hôtels de Monastir, Sousse et Hammam-Sousse avec nos deux camionnettes pour récupérer les repas que nous trions soigneusement et distribuons ensuite à nos protégés. Nos chiens et chats ne mangeant pas les légumes crus, nous les offrons aux voisins propriétaires d’herbivores."
        )}
      </p>
      <p className="jacard-p">
        {t(
          "Nous remercions chaleureusement ces hôtels car grâce à eux, nous pouvons aider beaucoup plus d’animaux."
        )}
      </p>
      <p className="jacard-p">
        {t(
          "Nous espérons que cette démarche de collaboration permettra le début d’un grand changement à Sousse et, si possible, dans toute la Tunisie."
        )}
      </p>
      <p className="jacard-p">
        {t(
          "Si vous cherchez où passer vos vacances, nous vous recommandons fortement ces hôtels « bienveillants » sans hésiter."
        )}
      </p>
      <Row className="jacollabs-grid">
        <JASmallCard
          src={Image1}
          title={`IBEROSTAR SELECTION KANTAOUI BAY`}
          href={
            "https://www.google.com/maps/place/Iberostar+Selection+Kantaoui+Bay/@35.9121491,10.5801236,17z/data=!3m1!4b1!4m9!3m8!1s0x12fd89a5c331b777:0xf8de86d8e26b76c1!5m2!4m1!1i2!8m2!3d35.9121491!4d10.5801236!16s%2Fg%2F11g7_m11hs?entry=ttu"
          }
        />
        <JASmallCard
          src={Image2}
          title={`IBEROSTAR SELECTION DIAR EL ANDALOUS`}
          href={`https://www.google.com/maps?cid=16646086380005679494&fbclid=IwAR2l6qAqRQ1mI2WS9PbaL8LLeAn6U0WYFsCNJGAbYDyppxQoA3z1IXfQj5M`}
        />
        <JASmallCard
          src={Image3}
          title={`JAZ TOUR KHALEF`}
          href={`https://www.google.com/maps?cid=14557769737402378622&fbclid=IwAR1yurpvHWNzvuwHj1Si5p-qY2iUXp7-lBXo4swDw0LejRwjQJ5ETRdwJWU`}
        />
        <JASmallCard
          src={Image4}
          title={`HILTON SKANES MONASTIR BEACH RESORT`}
          href={`https://www.google.com/maps?cid=10716391997862905950&fbclid=IwAR2p_RJT2S9zrRf_chz4WSY7JXlx57Sw6BfIq-3Qjtqi2MDiP2nuZQZ2JZQ`}
        />
        <JASmallCard
          src={Image5}
          title={`IBEROSTAR SELECTION KURIAT PALACE`}
          href={`https://www.google.com/maps?cid=12489692313945219590&fbclid=IwAR27jZyij9Tct1iAUmAvLJn6I2CRwRVy9ZjwRvW7MlUDETf3LgwuTrsUfo4`}
        />
        <JASmallCard
          src={Image6}
          title={`HÔTEL SOVIVA RESORT`}
          href={`https://www.google.com/maps?cid=11496185301892741906&fbclid=IwAR3UcNGsSVynPa_vDXd279LYSi89bMtLatKxQ0Cz8A7qArcmi4Fy_euWVf4`}
        />
      </Row>
    </Container>
  );
}
