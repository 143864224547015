import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import "../assets/styles/jacard.scss";

function JASmallCard({ src, title, href }) {
  return (
    <Row className="jasmallcard col-sm p-3">
      <Col>
        <Image src={src} width={300} height={250} className="jasmallcard-img" />
      </Col>
      <Col>
        <a href={href} className="align-self-center jasmallcard-a">
          {title}
        </a>
      </Col>
    </Row>
  );
}

export default JASmallCard;
