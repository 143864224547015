import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import JACarouselImage1 from "../assets/images/Carousel/JACarousel-image1.jpg";
import "../assets/styles/jacarousel.scss";
import { useTranslation } from "react-i18next";
export default function JACarousel() {
  const { t } = useTranslation();
  return (
    <Row className="jabanner">
      <Col class="title-inner">
        <div class="jatitle">
          <div class="jatitle-inner">Les jardins des anges</div>
        </div>
        <div class="jasubtitle">
          <div class="jasubtitle-inner">
            {t("Soutenir la cause animale en Tunisie")}
          </div>
        </div>
      </Col>

{/*       <Image src={JACarouselImage1} className="jabanner-img" />
 */}    </Row>
  );
}
