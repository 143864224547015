import React from "react";
import JAVideo3 from "../assets/videos/video-3.mp4";
import JAVideo4 from "../assets/videos/video-4.mp4";
import JAVideo5 from "../assets/videos/video-5.mp4";

import ReactPlayer from "react-player";
function JAVideos() {
  return (
    <div className="javideos d-flex">
      <ReactPlayer url={JAVideo5} controls />
      <ReactPlayer url={JAVideo3} controls />
      <ReactPlayer url={JAVideo4} controls />
    </div>
  );
}

export default JAVideos;
