import React from "react";
import { Card, Image } from "react-bootstrap";
import "../assets/styles/jacard.scss";

export default function JACard({ header, body, LeftImage, RightImage }) {
  return (
    <Card className="jacard d-flex align-items-center">
      <Card.Header className="jacard-header d-flex align-items-center">
        {" "}
        <Image src={LeftImage} width={90} className="img-paw" />
        <h1>{header}</h1>
        <Image src={RightImage} width={90} className="img-paw" />
      </Card.Header>
      <Card.Body>{body}</Card.Body>
    </Card>
  );
}
